<template>
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
</template>

<script setup>
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useMetaStore } from '~~/stores/metaStore'
import { storeToRefs } from 'pinia'
import debounceFn from 'debounce-fn'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const route = useRoute()
const metaStore = useMetaStore()
const { $bus } = nuxtApp
const { smoother } = storeToRefs(metaStore)
const smootherStopped = ref(false)

useHead(() => ({ link: [{ rel: 'canonical', href: config.public.frontBase + route.path }] }))
defineRobotMeta()

if (process.client) {
	smoother.value = new Lenis()
	smoother.value.on('scroll', ScrollTrigger.update)

	gsap.ticker.add((time) => {
		smoother.value.raf(time * 1000)
	})

	gsap.ticker.lagSmoothing(0)
}

function scrollTo(distance = 0, immediate = false) {
	const scrollTo = distance
	const currentScroll = window.scrollY
	const scrollDistance = distance ? distance - currentScroll : currentScroll - scrollTo
	const duration = (scrollDistance) <= 5000 ? 1 : scrollDistance ? ((scrollDistance / 5000) * 0.8) : 0.8

	smoother.value.scrollTo(scrollTo, { immediate: immediate, easing: (t) => easeInOutExpo(t), duration: duration })
}

function easeInOutExpo(x) {
	return x === 0
		? 0
		: x === 1
			? 1
			: x < 0.5 ? Math.pow(2, 20 * x - 10) / 2
				: (2 - Math.pow(2, -20 * x + 10)) / 2
}

function toggleSmoother() {
	if (smootherStopped.value) {
		smoother.value.start()
		smootherStopped.value = false
	} else {
		smoother.value.stop()
		smootherStopped.value = true
	}
}

const handleResize = () => {
	$bus.$emit('window-resized') // let components know that the window has been resized
}

function enableBusEvents(bool) {
	if (bool) {
		$bus.$on('scroll-to-top', (immediate) => { scrollTo(0, immediate) })
		$bus.$on('scroll-to-el', (params) => { (typeof params === 'string' || params instanceof String) ? smoother.value.scrollTo(params) : smoother.value.scrollTo(params.el, params.config) })
		$bus.$on('scroll-toggle', () => toggleSmoother())
		$bus.$on('scroll-start', () => smoother.value.start())
		$bus.$on('scroll-stop', () => smoother.value.stop())
		$bus.$on('scrolltrigger-refresh', debounceFn((safe) => { nextTick(() => ScrollTrigger.refresh(safe)) }, { wait: 100 }))
	} else {
		$bus.$off('scroll-to-top', (immediate) => { scrollTo(immediate) })
		$bus.$off('scroll-to-el', (params) => { (typeof params === 'string' || params instanceof String) ? smoother.value.scrollTo(params) : smoother.value.scrollTo(params.el, params.config) })
		$bus.$off('scroll-toggle', () => toggleSmoother())
		$bus.$off('scroll-start', () => smoother.value.start())
		$bus.$off('scroll-stop', () => smoother.value.stop())
		$bus.$off('scrolltrigger-refresh', debounceFn((safe) => { nextTick(() => ScrollTrigger.refresh(safe)) }, { wait: 100 }))
	}
}

onMounted(() => {
	window.addEventListener('resize', debounceFn(() => { handleResize() }, { wait: 100 }))

	enableBusEvents(true)
})

onBeforeUnmount(() => {
	window.removeEventListener('resize', debounceFn(() => { handleResize() }, { wait: 100 }))

	enableBusEvents(false)
})
</script>

<style lang="scss" scoped></style>